import React,{useState} from 'react'
import { useContext } from 'react'
import { AppContent, AppSidebar } from 'src/components'
import RightDrawer from 'src/components/RightDrawer'
import { GlobalContext } from 'src/context/Provider'

const CommonLayout = () => {
    const {showDrawer,setImage} = useContext(GlobalContext);
    
 
    
    return (
        <>
            <AppSidebar />
            <div
                className={`${
                showDrawer.show ? 'wrapper' : 'wrapper wrapper-fullwidth'
                } d-flex flex-column min-vh-100`}
            >
                <div className="main-container">
                    <AppContent />
                </div>
            </div>
            {showDrawer.show ? <RightDrawer setImage={setImage} /> : null}
        </>
    )
}

export default CommonLayout