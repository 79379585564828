import React from 'react'
import { CImage, CNavItem, CNavTitle } from '@coreui/react'
import {
  bmDocumentIcon,
  clientListIcon,
  currentWorkerIcon,
  dashboardIcon,
  inventoryIcon,
  invoiceRegistryIcon,
  leaveIcon,
  mpfIcon,
  payrollIcon,
  payslipIcon,
  potentialWorkerIcon,
  purchaseIcon,
  quotationRegistryIcon,
  reminderIcon,
  sickLeaveIcon,
  taxIcon,
  timesheetIcon,
  userRoleIcon,
} from './assets/icons'
let token = JSON.parse(localStorage?.getItem('bm-user-data'))

const _nav = [
  {
    component: CNavTitle,
    name: 'Menu',
  },
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CImage src={dashboardIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Potential Workers',
    to: '/potential-workers',
    icon: <CImage src={potentialWorkerIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Current Workers',
    to: '/current-workers',
    icon: <CImage src={currentWorkerIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Client List',
    to: '/clients',
    icon: <CImage src={clientListIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Reminder',
    to: '/reminders',
    icon: <CImage src={reminderIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Timesheets',
    to: '/timesheets',
    icon: <CImage src={timesheetIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'BM Documents',
    to: '/bm-documents',
    icon: <CImage src={bmDocumentIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Current Worker Leaves',
    to: 'current/leaves',
    icon: <CImage src={leaveIcon} className="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Accounting',
  },
  {
    component: CNavItem,
    name: 'Purchases',
    to: '/purchases',
    icon: <CImage src={purchaseIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Invoice Registry',
    to: '/invoice-registry',
    icon: <CImage src={invoiceRegistryIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Quotation Registry',
    to: '/quotation-registry',
    icon: <CImage src={quotationRegistryIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'MPF',
    to: '/mpfs',
    icon: <CImage src={mpfIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Taxes',
    to: '/taxes',
    icon: <CImage src={taxIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Payroll',
    to: '/payrolls',
    icon: <CImage src={payrollIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Inventory',
    to: '/inventories',
    icon: <CImage src={inventoryIcon} className="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'HR',
  },
  {
    component: CNavItem,
    name: 'Office Staffs',
    to: '/office-staffs',
    icon: <CImage src={mpfIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Leaves',
    to: '/leaves',
    icon: <CImage src={leaveIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Payslips',
    to: '/payslips',
    icon: <CImage src={payslipIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Sick Leaves',
    to: '/sick-leaves',
    icon: <CImage src={sickLeaveIcon} className="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Setting',
  },
  {
    component: CNavItem,
    name: 'User Roles',
    to: '/user-roles',
    icon: <CImage src={userRoleIcon} className="nav-icon" />,
  },
]
const siteArr = [
  {
    component: CNavTitle,
    name: 'Menu',
  },

  {
    component: CNavItem,
    name: 'Timesheets',
    to: '/timesheets',
    icon: <CImage src={timesheetIcon} className="nav-icon" />,
  },
]
const hrArr = [
  {
    component: CNavTitle,
    name: 'HR',
  },
  {
    component: CNavItem,
    name: 'Office Staffs',
    to: '/office-staffs',
    icon: <CImage src={mpfIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Leaves',
    to: '/leaves',
    icon: <CImage src={leaveIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Payslips',
    to: '/payslips',
    icon: <CImage src={payslipIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Sick Leaves',
    to: '/sick-leaves',
    icon: <CImage src={sickLeaveIcon} className="nav-icon" />,
  },
]
const accountArr = [
  {
    component: CNavTitle,
    name: 'Accounting',
  },
  {
    component: CNavItem,
    name: 'Purchases',
    to: '/purchases',
    icon: <CImage src={purchaseIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Invoice Registry',
    to: '/invoice-registry',
    icon: <CImage src={invoiceRegistryIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Quotation Registry',
    to: '/quotation-registry',
    icon: <CImage src={quotationRegistryIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'MPF',
    to: '/mpfs',
    icon: <CImage src={mpfIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Taxes',
    to: '/taxes',
    icon: <CImage src={taxIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Payroll',
    to: '/payrolls',
    icon: <CImage src={payrollIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Inventory',
    to: '/inventories',
    icon: <CImage src={inventoryIcon} className="nav-icon" />,
  },
]

let role = token?.user?.role

export default role === 'superadmin'
  ? _nav
  : role === 'hr'
  ? hrArr
  : role === 'account'
  ? accountArr
  : siteArr
