import React from 'react'
import Button404 from '../assets/images/button404.png'
import Page from '../assets/images/PageNotFound.png'
import Return from '../assets/images/Return.png'
import { useNavigate } from 'react-router-dom'
import { token } from 'src/layout/AppHeader'
const NotFound = () => {
  let history=useNavigate()
  return (
    <div style={{ width: token?.user?.role?"60%":"50%", overflow: 'hidden', margin: 'auto', marginTop: '100px' }}>
      <img src={Button404} style={{ objectPosition: 'end', objectFit: 'cover' }} />
      <img src={Page} style={{ objectPosition: 'end', objectFit: 'cover' }} />
     {token?.user?.role==="superadmin" && <div className="text-center mt-5" onClick={()=>history("/dashboard")}>
        <img src={Return} style={{ objectPosition: 'end', objectFit: 'cover',cursor:"pointer" }} />
      </div>}
    </div>
  )
}

export default NotFound
