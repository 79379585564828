import React from 'react'
import PropTypes from 'prop-types'
import bmLogo from '../../assets/images/bmLogo.png'
const Layout = (props) => {
  const { children } = props
  return (
    <div className="left-section col-md-6 ">
      <div className="logo-section">
        <img src={bmLogo} alt="bmLogo" />
      </div>

      {children}
      <div className="bottom-content">{new Date().getFullYear()} BM Constructions Pvt. Ltd. All rights reserved.</div>
    </div>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.object,
}
