import React, { useRef, useEffect, useState } from 'react'
import { useContext } from 'react'
import { GlobalContext } from 'src/context/Provider'
import { handleDrawerShow } from 'src/context/actions/drawer'
import api from 'src/services/api'
import { useNavigate } from 'react-router-dom'
import arrowRight from '../assets/images/arrowRight.svg'
import notification from '../assets/images/notification.svg'
import userProfile from '../assets/images/avatars/profile.png'
import CarRepair from '../assets/images/carRepair.png'
import camera from '../assets/images/camera.png'
import Ellipse5 from '../assets/images/Ellipse 5.png'
import Ellipse6 from '../assets/images/Ellipse 6.png'
import Ellipse7 from '../assets/images/Ellipse 7.png'
import Ellipse from '../assets/images/Ellipse.png'
import config from 'src/config'
import { token } from 'src/layout/AppHeader'
import { previewImage } from 'src/helpers/getImage'

// let reminderData = [
//   { title: 'Car Repairing', desc: 'Worker needed at Site-6 hours ago', id: 1, image: CarRepair },
//   { title: 'Birthday', desc: 'Employee 2 Birthday! -6 hours ago', id: 2, image: Birthday },
//   { title: 'Car Repairing', desc: 'Worker needed at Site-6 hours ago', id: 3, image: CarRepair },
//   { title: 'Car Repairing', desc: 'Worker needed at Site-6 hours ago', id: 4, image: Birthday },
// ]
const RightDrawer = () => {
  const [data, setData] = useState([])
  const [imagePreview, setImagepreview] = useState('')
  const [role, setRole] = useState('')

  const [name, setName] = useState('')

  const [loagoutShow, setLogoutShow] = useState(false)

  // const[imagePath,setImagePath]=useState()
  let navigate = useNavigate()
  const fetchAllReminder = async (signal) => {
    try {
      const data = await api.get(`/admin/reminder/data`, { signal })

      setData(data?.data)
    } catch (error) {}
  }

  useEffect(() => {
    if (token?.user?.role !== 'superadmin') return
    let controller = new AbortController()
    let { signal } = controller
    fetchAllReminder(signal)
    return () => controller.abort()
  }, [])
  const fileRef = useRef()
  const { setShowDrawer, setImage } = useContext(GlobalContext)

  const fileSend = async (file) => {
    const formData = new FormData()
    formData.append('avatar', file)
    try {
      const data = await api.post('/user/profile/avatar', formData)
      setImage(`${config.app.apiUrl?.replace('v1', '')}${data?.data?.avatar}`)
      return data
    } catch (error) {
      toast.error(error.message)
    }
  }
  //for image chnage
  const imageChange = async (e) => {
    let file = e.target.files[0]
    setImagepreview(URL.createObjectURL(file))
    try {
      let data = await fileSend(file)
      setImage && setImage(`${config.app.apiUrl?.replace('v1', '')}${data?.data?.avatar}`)
    } catch (err) {}
  }

  //for details of user
  const fetchUserDetail = async (signal) => {
    try {
      const data = await api.get(`/user/profile`, { signal })
      setName(() => {
        let nameSplit = data?.data?.name?.split(' ')
        return `${nameSplit?.[0]} ${nameSplit?.[1]?.slice(0, 1)?.toUpperCase() || 'A'}.`
      })
      setRole(data?.data?.role)


      setImagepreview(previewImage(data?.data?.avatar))
    } catch (error) {}
  }

  useEffect(() => {
    let controller = new AbortController()
    let { signal } = controller
    fetchUserDetail(signal)
    return () => controller.abort()
  }, [])

  return (
    <div className="right-drawer">
      <div className="content">
        <img
          className="arrow-right"
          onClick={() => setShowDrawer(handleDrawerShow(true))}
          src={arrowRight}
          alt="arrow-right"
        />
		<div className="details-section details-section-drawer">
			{/* {token?.user?.role === 'superadmin' && (
			<div className="outer-image_cover">
				<img src={notification} alt="notification" />
			</div>					  
			)} */}
          <div className="outer-image_cover">
            <img
              // onClick={() => setShowDrawer(handleDrawerShow(true))}
              onClick={() => setLogoutShow(!loagoutShow)}
              src={imagePreview || userProfile}
              className="avatar"
              alt="avatar"
            />
            {loagoutShow && (
              <div
                className="header-logout"
                onClick={() => {
                  localStorage.removeItem('bm-user-data'), (window.location = '/')
                }}
              >
                Logout
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="imgContainer">
        {' '}
        <img src={imagePreview || userProfile} className="imgItem" alt="avatar" />
        <div
          className="photoText"
          onClick={() => fileRef.current.click()}
          style={{
            backgroundImage: `url(${Ellipse})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',

            width: '100%',
            height: '65px',
          }}
        >
          <div>
            <img src={camera} alt="" />
          </div>
          <div>Choose Photo</div>
        </div>
        <div className="headerPart">
          <div className="headerName">{name}</div>
          <div className="headerRole">{role}</div>
        </div>
      </div>
      <input
        type="file"
        hidden
        name=""
        id=""
        ref={fileRef}
        onChange={imageChange}
        accept="image/*"
      />
      {token?.user?.role === 'superadmin' && (
        <div className="reminderContainer">
          <div>
            <div className="">Reminders</div>
            <div
              onClick={() => {
                navigate('/reminders')

                setShowDrawer(handleDrawerShow(false))
              }}
            >
              See all
            </div>
          </div>

          {data?.length > 0 ? (
            data?.map((val, index) => {
              return (
                <div className="reminderItems" key={index}>
                  <div>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        backgroundImage: `url(${Ellipse5})`,
                        height: '50px',
                        width: '50px',
                        borderRadius: '50%',

                        padding: '5px',
                      }}
                    >
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          backgroundImage: `url(${Ellipse6})`,
                          height: '42.5px',
                          width: '42.5px',
                          borderRadius: '50%',
                          padding: '5px',
                        }}
                      >
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            backgroundImage: `url(${Ellipse7})`,
                            height: '32.5px',
                            width: '32.5px',
                            borderRadius: '50%',
                          }}
                        >
                          <img src={CarRepair} className="reminderImage" alt="avatar" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>{val?.title}</div>
                    <div>
                      {val?.description?.length > 30
                        ? val?.description?.slice(0, 25) + '...'
                        : val?.description}
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <div className="text-center pt-3">No data found</div>
          )}
        </div>
      )}
    </div>
  )
}

export default RightDrawer
