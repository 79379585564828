import React, { useState } from 'react'
import { useReducer } from 'react'
import { createContext } from 'react'
import drawerReducer, { DrawerInitialState } from './reducers/drawer'

export const GlobalContext = createContext({})

const GlobalContextProvider = (props) => {
  const [showDrawer, setShowDrawer] = useReducer(drawerReducer, DrawerInitialState)
  const [image, setImage] = useState('')
  // const [role, setRole] = useState('check')

  return (
    <GlobalContext.Provider value={{ showDrawer, setShowDrawer, image, setImage,  }}>
      {props.children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
