import React, { useState, useEffect } from 'react'
import { CHeader } from '@coreui/react'
import notification from '../assets/images/notification.svg'
import arrowLeft from '../assets/images/arrowLeft.svg'
import { useContext } from 'react'
import { GlobalContext } from 'src/context/Provider'
import { handleDrawerShow } from 'src/context/actions/drawer'
import config from 'src/config'
import api from 'src/services/api'
import { previewImage } from 'src/helpers/getImage'
export let token = JSON.parse(localStorage.getItem('bm-user-data'))

const AppHeader = ({ title, search }) => {
  const [imagee, setImage] = useState('')
  const { showDrawer, setShowDrawer,image } = useContext(GlobalContext)
  const [loagoutShow, setLogoutShow] = useState(false)
  const fetchUserDetail = async (signal) => {
    try {
      const data = await api.get(`/user/profile`, { signal })
      setImage(previewImage(data?.data?.avatar))
    } catch (error) {}
  }

  useEffect(() => {
    let controller = new AbortController()
    let { signal } = controller
    fetchUserDetail(signal)
    return () => controller.abort()
  }, [])
  return (
    <CHeader position="sticky" className="header-section">
      <div className="">
        <div>{title}</div>
        {/* <div>{search}</div> */}
      </div>
      {!showDrawer.show ? (
        <div className="details-section">
          <>
            {' '}
            <img
              src={arrowLeft}
              alt="arrow-right"
              onClick={() => setShowDrawer(handleDrawerShow(true))}
			/>
			{token?.user?.role === 'superadmin' && (
			<div className="outer-image_cover">
				<img src={notification} onClick={() => setShowDrawer(handleDrawerShow(true))} alt="notification" />
			</div>						  
			)}
          </>
          <div className="outer-image_cover">
            <img
              src={((image||imagee))}
              className="avatar"
              alt="avatar"
              onClick={() => setLogoutShow(!loagoutShow)}
            />
            {loagoutShow && (
              <div
                className="header-logout"
                onClick={() => {
                  localStorage.removeItem('bm-user-data'), (window.location = '/')
                }}
              >
                Logout
              </div>
            )}
          </div>
        </div>
      ) : null}
    </CHeader>
  )
}

export default AppHeader
