import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import "./main.css"
import { GlobalContext } from './context/Provider'
import { useContext } from 'react'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
import CommonLayout from './layout/CommonLayout'
import { roleChecker } from './helpers/roleChecker'
import NotFound from './views/NotFound'

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/NotFound'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const AddWorker =React.lazy(() => import('./views/pages/public/AddWorker'))

const App = () => {
  // const {role,setRole}=useContext(GlobalContext);
  let role=JSON.parse(localStorage.getItem("bm-user-data"))?.user?.role;

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>

          <Route exact path="/" name="Login Page" element={<Login />} />
        <Route  exact path="/add-worker"  element={<AddWorker/>} />

          <Route exact path="/register" name="Register Page" element={<Register />} />
     
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={role?<CommonLayout/>:<Page404/>} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
