import React from 'react'
import Login from './components/Authentication/Login'
import NotFound from './views/NotFound'
import Page404 from './views/pages/page404/Page404'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const PotentialWorkers = React.lazy(() => import('./views/potentialworkers/PotentialWorkers'))
const AddNewWorkerPotentialWorker = React.lazy(() =>
  import('./views/potentialworkers/AddNewWorker'),
)
const AddNewWorkerCurrentWoker = React.lazy(() => import('./views/currentworker/AddNewWorker'))

const CurrentWorker = React.lazy(() => import('./views/currentworker/CurrentWorker'))
const ClientList = React.lazy(() => import('./views/clients/Client'))
const AddClient = React.lazy(() => import('./views/clients/AddClient'))
const ViewClient = React.lazy(() => import('./views/clients/ViewClient'))
const Reminder = React.lazy(() => import('./views/reminder/Reminder'))
const TimeSheet = React.lazy(() => import('./views/timesheet/Timesheet'))
const ViewCompanyTimesheet = React.lazy(() => import('./views/timesheet/ViewCompanyTimesheet'))
const Document = React.lazy(() => import('./views/document/Document'))
const WorkerLeave = React.lazy(() => import('./views/leave/workerLeave/WorkerLeave'))
// Accounting
const Purchase = React.lazy(() => import('./views/purchase/Purchase'))
const InvoiceRegistry = React.lazy(() => import('./views/invoiceregistry/InvoiceRegistry'))
const ViewInvoiceRegistry = React.lazy(() => import('./views/invoiceregistry/ViewInvoiceRegistry'))
const Quotation = React.lazy(() => import('./views/quotation/Quotation'))
const ViewQuotation = React.lazy(() => import('./views/quotation/ViewQuotation'))
const MPF = React.lazy(() => import('./views/mpf/MPF'))
const Tax = React.lazy(() => import('./views/tax/Tax'))
const Payroll = React.lazy(() => import('./views/payroll/Payroll'))
const ViewPayroll = React.lazy(() => import('./views/payroll/ViewPayroll'))
const PayrollCalculation = React.lazy(() => import('./views/payroll/PayrollCalculation'))
const AccountPayslip = React.lazy(() => import('./views/payroll/payslip/AccountPayslip'))
const ViewAccountPayslip = React.lazy(() => import('./views/payroll/payslip/ViewAccountPayslip') )
const Inventory = React.lazy(() => import('./views/inventory/Inventory'))
// HR
const OfficeStaff = React.lazy(() => import('./views/staff/Staff'))
const Leave = React.lazy(() => import('./views/leave/Leave'))
const Payslip = React.lazy(() => import('./views/payslip/Payslip'))
const ViewPayslip = React.lazy(() => import('./views/payslip/ViewPayslip'))
const SickLeave = React.lazy(() => import('./views/leave/SickLeave'))
// Settings
const UserRole = React.lazy(() => import('./views/userrole/UserRole'))
let token = JSON.parse(localStorage?.getItem('bm-user-data'))

const routes = [
  // MENU
  // { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard, exact: true },

  { path: '/potential-workers', name: 'Potential Workers', element: PotentialWorkers, exact: true },
  {
    path: '/potential-workers/add-new-worker',
    name: 'Add New Worker',
    element: AddNewWorkerPotentialWorker,
    exact: true,
  },
  {
    path: '/potential-workers/edit-worker/:id',
    name: 'Edit Potential Worker',
    element: AddNewWorkerPotentialWorker,
    exact: true,
  },
  {
    path: '/current-workers/add-new-worker',
    name: 'Add New Worker',
    element: AddNewWorkerCurrentWoker,
    exact: true,
  },
  {
    path: '/current-workers/edit/:id',
    name: 'Edit Current Worker',
    element: AddNewWorkerCurrentWoker,
    exact: true,
  },
  {
    path: '/clients/add-new-client',
    name: 'Add Client',
    element: AddClient,
    exact: true,
  },
  {
    path: '/client/edit-client/:id',
    name: 'Edit Client',
    element: AddClient,
    exact: true,
  },
  {
    path: '/clients/:id',
    name: 'View Client',
    element: ViewClient,
    exact: true,
  },
  { path: '/current-workers', name: 'Current Workers', element: CurrentWorker, exact: true },
  { path: '/clients', name: 'Client List', element: ClientList, exact: true },
  { path: '/reminders', name: 'Reminders', element: Reminder, exact: true },
  { path: '/timesheets', name: 'Timesheets', element: TimeSheet, exact: true },
  {
    path: '/timesheets/:companyId/:companyName',
    name: 'Timesheets',
    element: ViewCompanyTimesheet,
    exact: true,
  },
  { path: '/bm-documents', name: 'BM Documents', element: Document, exact: true },
  // ACCOUNTING
  { path: '/purchases', name: 'Purchases', element: Purchase, exact: true },
  { path: '/invoice-registry', name: 'Invoice Registry', element: InvoiceRegistry, exact: true },
  { path: '/invoice-registry/:id/:dateString', name: 'View Invoice Registry', element: ViewInvoiceRegistry, exact: true },
  { path: '/quotation-registry', name: 'Quotation Registry', element: Quotation, exact: true },
  {
    path: '/quotation-registry/1',
    name: 'View Quotation Registry',
    element: ViewQuotation,
    exact: true,
  },
  { path: '/mpfs', name: 'MPF', element: MPF, exact: true },
  { path: '/taxes', name: 'Taxes', element: Tax, exact: true },
  { path: '/payrolls', name: 'Payroll', element: Payroll, exact: true },
  { path: '/payrolls/:companyId/:companyName', name: 'View Payroll', element: ViewPayroll, exact: true },
  { path: '/payrolls/calculation/:companyId/:companyName/:dateString', name: 'Payroll Calculation', element: PayrollCalculation, exact: true },
  { path: '/payrolls/payslips/:companyId/:companyName', element: AccountPayslip, exact: true },
  { path: '/payrolls/payslips/detail/:companyId/:workerId/:bmCode', element: ViewAccountPayslip, exact: true },
  { path: '/inventories', name: 'Inventory', element: Inventory, exact: true },
  // HR
  { path: '/office-staffs', name: 'Office Staffs', element: OfficeStaff, exact: true },
  { path: '/leaves', name: 'Leaves', element: Leave, exact: true },
  { path: 'current/leaves', name: 'Current Leaves', element: WorkerLeave, exact: true },
  { path: '/payslips', name: 'Payslips', element: Payslip, exact: true },
  { path: '/payslips/:id', name: 'View Payslip', element: ViewPayslip, exact: true },
  { path: '/sick-leaves', name: 'Sick Leaves', element: SickLeave, exact: true },
  // SETTING
  { path: '/user-roles', name: 'User Roles', element: UserRole, exact: true },
  { path: '*', name: 'Not Found', element: NotFound, exact: true },
]
const siteRoutes = [
  { path: '/timesheets', name: 'Timesheets', element: TimeSheet, exact: true },
  {
    path: '/timesheets/:companyId/:companyName',
    name: 'Timesheets',
    element: ViewCompanyTimesheet,
    exact: true,
  },
  { path: '*', name: 'Not Found', element: NotFound, exact: true },
]
const accountingRoutes = [
  { path: '/purchases', name: 'Purchases', element: Purchase, exact: true },
  { path: '/invoice-registry', name: 'Invoice Registry', element: InvoiceRegistry, exact: true },
  {
    path: '/invoice-registry/:id/:dateString',
    name: 'View Invoice Registry',
    element: ViewInvoiceRegistry,
    exact: true,
  },
  { path: '/quotation-registry', name: 'Quotation Registry', element: Quotation, exact: true },
  {
    path: '/quotation-registry/1',
    name: 'View Quotation Registry',
    element: ViewQuotation,
    exact: true,
  },
  { path: '/mpfs', name: 'MPF', element: MPF, exact: true },
  { path: '/taxes', name: 'Taxes', element: Tax, exact: true },
  { path: '/payrolls', name: 'Payroll', element: Payroll, exact: true },
  { path: '/payrolls/:companyId/:companyName', name: 'View Payroll', element: ViewPayroll, exact: true },
  { path: '/payrolls/calculation/:companyId/:companyName/:dateString', name: 'Payroll Calculation', element: PayrollCalculation, exact: true },
  { path: '/payrolls/payslips/:companyId/:companyName', element: AccountPayslip, exact: true },
  { path: '/payrolls/payslips/detail/:companyId/:workerId/:bmCode', element: ViewAccountPayslip, exact: true },
  { path: '/inventories', name: 'Inventory', element: Inventory, exact: true },
  { path: '*', name: 'Not Found', element: NotFound, exact: true },
]
const hrRoutes = [
  { path: '/office-staffs', name: 'Office Staffs', element: OfficeStaff, exact: true },
  { path: '/leaves', name: 'Leaves', element: Leave, exact: true },
  { path: '/payslips', name: 'Payslips', element: Payslip, exact: true },
  { path: '/payslips/:id', name: 'View Payslip', element: ViewPayslip, exact: true },
  { path: '/sick-leaves', name: 'Sick Leaves', element: SickLeave, exact: true },
  { path: '*', name: 'Not Found', element: NotFound, exact: true },
]

// export default routes

let role = token?.user?.role

export default role === 'superadmin'
  ? routes
  : role === 'hr'
  ? hrRoutes
  : role === 'account'
  ? accountingRoutes
  : role === 'sitemanager'
  ? siteRoutes
  :  [{ path: '*', name: 'Office Staffs', element: <Login/>, exact: true }]
