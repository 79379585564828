import axios from 'axios'
import config from '../config'
import { isServer } from '../utils/helper'
import { toast } from 'react-toastify'
const api = axios.create({
  baseURL: config.app.apiUrl,
})

// api.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
api.defaults.withCredentials = true
api.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem('bm-user-data')
    ? JSON.parse(localStorage.getItem('bm-user-data')).accessToken
    : ''
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : ''
  return config
})

api.interceptors.response.use(
  async (response) => {
    if (response.status >= 200 && response.status < 300) {
      const data = response.data
      return Promise.resolve(data)
    }
  },
  async (error) => {
    const { response, request } = error
    if (response) {
      if (response.status === 401) {
        localStorage.removeItem('bm-user-data')

        window.location.href = '/'
      }
      if (response.status === 403) {
        toast.error(response.data.message)
      }
      if (response.status >= 400 && response.status < 500) {
        return Promise.reject(response.data)
      }
    } else if (request) {
      return null
    }
    return Promise.reject(error)
  },
)

export default api
