import { DRAWER_SHOW } from "../actions/drawer";

export const DrawerInitialState = {
    show: false,
};

// reducer function
const drawerReducer = (state, { payload, type }) => {
    switch (type) {
        case DRAWER_SHOW:
            return { ...state, show: !state.show };
        default:
            return DrawerInitialState;
    }
};
export default drawerReducer;












