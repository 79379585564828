import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import api from 'src/services/api'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { AiFillEye } from 'react-icons/ai'
import { AiFillEyeInvisible } from 'react-icons/ai'

const Login = ({ setCurrentState, setRole }) => {
  const navigate = useNavigate()

  const [loginCreds, setLoginCreds] = useState({ email: '', password: '' })
  const [rememberMe, setRememberMe] = useState(false)
  const [errors, setErrors] = useState({})
  const [passwordType, setPasswordType] = useState('password')
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email)
  }
  function isValidPassword(password) {
    return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(password)
  }
  const validate = (values) => {
    let errors = {}
    if (!values.email) {
      errors.email = 'Email is Required.'
    }
    if (values.email && !isValidEmail(values.email)) {
      errors.email = 'Invalid Email.'
    }
    if (!values.password) {
      errors.password = 'Password is Required.'
    }
    if (values.password && !isValidPassword(values.password)) {
      errors.password =
        'Password must be minimum 8 characters, at least one letter, one number and one special character'
    }
    return errors
  }
  const submitHandler = async (e) => {
    e.preventDefault()
    setErrors({})

    if (Object.keys(validate(loginCreds)).length) {
      setErrors(validate(loginCreds))
      return
    }
    // api call
    try {
      const data = await api.post('/auth/login', loginCreds)
      if (data && data.ok) {
        if (rememberMe) {
          localStorage.setItem('bm-user', loginCreds.email)
          localStorage.setItem('remember', rememberMe)
        } else {
          localStorage.setItem('remember', false)
          localStorage.removeItem('bm-user')
        }
        localStorage.setItem('bm-user-data', JSON.stringify(data))

        // let role = data?.user?.role
        // setRole(role)
        // setRole(role)

        toast.success(data.message)

        window.location = '/'
        // setTimeout(()=>{
        //   navigate('/dashboard')
        // },200)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const changeHandler = (e) => {
    setLoginCreds({ ...loginCreds, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    let rememberCheck = localStorage.getItem('remember')
    if (rememberCheck === 'true' && localStorage.getItem('bm-user')) {
      setLoginCreds({ ...loginCreds, email: localStorage.getItem('bm-user') })
    }
  }, [])

  return (
    <>
      <Layout>
        <form onSubmit={submitHandler}>
          <div className="title">Admin Login</div>

          <div className="form-controller">
            <div className="label-field">
              <label htmlFor="email">Email</label>
              <span className="isrequired">*</span>
            </div>
            <input
              type="text"
              className={`${errors.email && 'red-bordered'}`}
              value={loginCreds.email}
              onChange={changeHandler}
              name="email"
              placeholder="Enter Email"
            />
            {errors.email && <div className="error-msg">{errors.email}</div>}
          </div>
          <div className="form-controller">
            <div className="label-field">
              <label htmlFor="password">Password</label>
              <span className="isrequired">*</span>
            </div>{' '}
            <div className="password-relative">
              <input
                type={passwordType}
                className={`${errors.password && 'red-bordered'}`}
                value={loginCreds.password}
                onChange={changeHandler}
                name="password"
                placeholder="Password"
              />
              <span
                style={{ cursor: 'pointer' }}
                onMouseDown={() => setPasswordType('text')}
                onMouseUp={() => setPasswordType('password')}
              >
                {passwordType === 'password' ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
            {errors.password && <div className="error-msg">{errors.password}</div>}
          </div>
          <div className="remember-forgot_section">
            <label className="container-checkbox login-page">
              <input
                type="checkbox"
                name="rememberMe"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <span className="text-remember">Remember Me</span>
              <span className="checkmark"></span>
            </label>
            <span className="forgot-password" onClick={() => setCurrentState('forgotPassword')}>
              Forgot Password
            </span>
          </div>
          <button type="submit" className="submit-btn">
            Login
          </button>
        </form>
      </Layout>
    </>
  )
}

export default Login
Login.propTypes = {
  setCurrentState: PropTypes.object,
}
