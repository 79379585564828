import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react'
import routes from '../routes'

const AppContent = () => {
  
  // check for user data in local storage

  let bmUser = localStorage.getItem('bm-user-data')
  return (
    <div className="container-fluid">
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={bmUser ? <route.element /> : <Navigate to="/" />}
                />
              )
            )
          })}
        </Routes>
      </Suspense>
    </div>
  )
}

export default React.memo(AppContent)
