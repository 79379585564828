import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import GlobalContextProvider from './context/Provider'
import { Provider } from 'react-redux'
import store from './store'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'

createRoot(document.getElementById('root')).render(
  <GlobalContextProvider>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </GlobalContextProvider>
)
